.containerLogin {
    background-color: #2185D0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card {
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 500px;
    background-color: #fafafa;
    border-radius: 10px;
    padding: 40px;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
}

.logoCard {
    width: 60px;
    height: 60px;
    border-radius: 10px;
    /* border: 1px solid #ddd; */
    box-shadow: 0px 3px 5px 0px #eaeaea;
}

.link {
    text-decoration: none;
}

.link:hover {
    text-decoration: underline;
}

.legenda {
    font-size: 12px;
    color: #777;
}

.corpoDialog{
    width: 500px;
}