.bodySelecaoOS {
    padding: 25px;
    background-color: #fafafa;
}

.atributosbarraDePesquisa {
    font-size: 16px;
    font-weight: 500;
    color: #333;
    margin: 0;
    margin-bottom: 10px;
}

.barraDePesquisa {
    display: flex;
    align-items: center;
    padding: 0 10px;
    flex: 1;
}

.containerAtributoBarraPesquisa {
    width: fit-content;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
}

.select {
    width: 100%;
    flex: 1;
    border: 0;
    height: 36px;
    font-size: 14px;
    border-radius: 5px;
    color: #333;
    outline: 0;
    padding: 5px 10px;
    background-color: transparent;
    border: 1px solid #ddd;
}

.barraResumo {
    display: flex;
    /* flex: 1; */
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    /* justify-content: flex-end; */
    /* margin-left: 50px; */
    margin-top: 20px;
}

.containerCardResumo {
    display: flex;
    flex-direction: column;
    width: 110px;
    height: 80px;
    border-radius: 10px;
    border: 3px solid #333;
    /* margin-right: 15px; */
    margin-left: 15px;
    background-color: #fff;
}

.containerCardResumo {
    cursor: pointer;
}

.containerCardResumoDisabled{
    display: flex;
    flex-direction: column;
    width: 110px;
    height: 80px;
    border-radius: 10px;
    border: 3px solid #333;
    margin-left: 15px;
    background-color: #fff;
    opacity: 0.3;
}

.cardCinzaEscuro {
    border: 3px solid #333;
}

.cabecalhoCardResumo {
    width: 100%;
    height: 30px;
    line-height: 30px;
    text-align: center;
}

.cabecalhoCardResumo h4 {
    margin: 0;
    color: #eee;
    font-size: 16px;
}

.bodyCardResumo {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.bodyCardResumo h4 {
    font-size: 30px;
    font-weight: 500;
    margin: 0;
}

.containerAccordions {
    margin-top: 50px;
}

.containerModal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* width: 400px; */
    background-color: #fff;
    padding: 20px 40px;
    border-radius: 10px;
    box-shadow: 0 2px 20px 0px #555;
    text-align: center;
}

.containerCabecalhoAccordion {
    width: 90vw;
    display: flex;
    align-items: center;
}

.containerAtributoCabecalhoAccordion {
    border-right: 1px solid #ccc;
    padding-right: 15px;
    margin-left: 20px;
}

.containerAtributoCabecalhoAccordion h5 {
    margin: 0;
    font-weight: 500;
    font-size: 14px;
}

.containerAtributoCabecalhoAccordion label {
    font-size: 14px;
    color: #222;
}

.atributosItemCabecalhoItemOS {
    font-weight: 500;
    margin-left: 50px;
}

.containerConteudoItemOS {
    display: flex;
    align-items: center;
    overflow-x: scroll;
}


.containerConteudoItemOS div h4 {
    margin: 0;
    margin-bottom: 5px;
    border-bottom: 1px solid #aaa;
    padding-right: 30px;
}

.containerConteudoItemOS::-webkit-scrollbar {
    width: 12px;
    height: 8px;
    /* width of the entire scrollbar */
}

.containerConteudoItemOS::-webkit-scrollbar-track {
    background: transparent;
    /* color of the tracking area */
}

.containerConteudoItemOS::-webkit-scrollbar-thumb {
    background-color: #ccc;
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
    border: 3px solid transparent;
    /* creates padding around scroll thumb */
}

.osDescricao {
    width: 25%
}

.osLocalDeEstoque {
    width: 25%;
}

.osCodEquip {
    width: 100px;
}

.osDataAbertura {
    width: 150px;
}

.osDataEncerramento {
    width: 150px;
}

/* Rastreamentos */
.containerCardsRastreamento {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
}

.cardRastreamento {
    width: 300px;
    /* height: 200px; */
    background-color: #FEDC7A;
    margin: 0 20px 20px 0;
    border-radius: 20px;
    padding: 20px;
    transition: background-color 0.2s;
}

.cardRastreamento:hover {
    background-color: #eeca5f;
    transition: background-color 0.3s;
}

.cardRastreamento h4 {
    font-size: 16px;
    color: #555;
    font-weight: bold;
    margin: 0;
    margin-bottom: 20px;
}

.cardRastreamento p {
    margin: 2px 0;
    font-size: 16px;
    color: #555;
}

.ultimoCard {
    background-color: #0069B8;
}

.ultimoCard:hover {
    background-color: #015a9e;
    transition: background-color 0.3s;
}

.ultimoCard h4 {
    color: #eee;
}

.ultimoCard p {
    color: #eee;
}

.codigoDescricaoItem {
    width: 40%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.numeroMovimentoItem {
    width: 35%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.previsaoEntregaItem {
    width: 25%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tabelaExcel th {
    border: 1px solid #aaa;
    padding: 5px;
}

.tabelaExcel td {
    border: 1px solid #aaa;
    padding: 5px;
}

.espacoTabela {
    height: 15px;
}

.linkResponsavel {
    text-decoration: underline;
}

.linkResponsavel:hover {
    cursor: pointer;
}