.divInput {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.containerInput {
    width: 100%;
    border: 1px solid #ddd;
    height: 100%;
    border-radius: 5px;
    outline: 0;
    /* padding: 5px 10px; */
    margin: 7px 0;
    font-size: 14px;
    color: #333;
    background-color: #fff;
    display: flex;
    align-items: center;
}

.containerInput:hover {
    border: 1px solid #bbb;
}

.containerInputErro {
    border: 1px solid #eee;
    height: 25px;
    border-radius: 5px;
    outline: 0;
    padding: 5px 10px;
    margin: 7px 0;
    font-size: 14px;
    background-color: #ffebee;
    border: 1px solid #ffcdd2;
}

.input {
    width: 100%;
    flex: 1;
    border: 0;
    height: 25px;
    font-size: 14px;
    border-radius: 5px;
    color: #333;
    outline: 0;
    padding: 5px 10px;
    background-color: transparent;
}

.input:focus {
    background-color: #fafafa;
}

.label {
    font-size: 14px;
    color: #555;
}

.mensagemErro {
    align-self: flex-end;
    font-size: 12px;
    color: #f44336;
}

.botaoIcone {
    border: 0;
    background-color: transparent;
}

.buttonInput{
    width: 50px;
    height: 36px;
    border: 0;
    border-radius: 0 5px 5px 0;
    background-color: #00518D;
    color: #eee;
    font-weight: bold;
    transition: background-color 0.2s;
}

.buttonInput:hover{
    background-color: #003f6f;
    transition: background-color 0.3s;
    cursor: pointer;
}
.buttonInput:active{
    background-color: #002d50;
}