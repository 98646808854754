.containerButton {
    background-color: #00518D;
    border-radius: 5px;
    border: 0;
    transition: background-color 0.4s;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 10px;
}

.button {
    padding: 10px;
    color: #eee;
    font-weight: 500;
    border: 0;
    background-color: transparent;
    user-select: none;
    font-size: 16px;
}

.button:hover {
    cursor: pointer;
}

.containerButton:hover {
    background-color: #014b83;
    cursor: pointer;
    transition: background-color 0.1s;
}

.containerButton:active {
    background-color: #014477;
}

.loading{
    opacity: 0.8;
}

.loading:hover{
    cursor: default;
}

.loading button:hover{
    cursor: default;
}