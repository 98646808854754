.header {
    background-color: #13131a;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tituloHeader {
    margin: 0;
    color: #eee;
    font-size: 22px;
    font-weight: 500;
    margin-left: 20px;
}

.nomeUsuario {
    font-weight: 500;
    color: #eee;
    font-size: 16px;
    margin-right: 20px;
}

.sair {
    color: #888;
}

.sair:hover {
    cursor: pointer;
    text-decoration: underline;
}

.ultimaAtualizacao{
    color: #888;
    margin-right: 50px;
}